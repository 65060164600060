.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav p {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav p:hover {
    color: #81818181;
}

/* .sidenav p:nth-child(2) {
    font-weight: bolder;
} */


.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}

#nav-button {
    filter: invert(1);
}

.sidenav .active {
    color: #f1f1f1;
}

/* ------------------- */
@media screen and (min-width: 951px) {
    .sidenav {
        display: none;
    }
}