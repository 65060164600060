@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Edu+SA+Beginner:wght@500&display=swap');

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  text-align: center;
  background-color: #AE7BA3;
  background-color: rgb(95, 95, 165);
  /* background-color: #90c6ca; */
  /* background-color: #7089A3; */
  /* background-color: #94B1D4; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", "Raleway", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
