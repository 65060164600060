.main .content {
  position: relative;
  background: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assests/BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(126, 107, 108, 0.171);
  background-blend-mode: multiply;
}
.main .content .name-tag,
.main .content .bio-tag {
  position: absolute;
  width: 100%;
}
.main .content .name-tag {
  top: 40%;
}
.main .content .bio-tag {
  top: 55%;
}
.main .content h1 {
  color: white;
  display: block;
  letter-spacing: 17px;
  width: 100%;
  padding: 0 5px;
  text-align: center;
  font-size: 56px;
  font-weight: 100;
  text-transform: uppercase;
}
.main .content h5 {
  color: white;
  text-align: center;
  letter-spacing: 15px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: block;
}
@media screen and (max-width: 513px) {
  .main .content h1 {
    font-size: 50px;
    letter-spacing: 9px;
  }
  .main .content h5 {
    font-size: 13px;
    letter-spacing: 7px;
    font-weight: 600;
  }
  .main .content {
    background-image: url(/src/assests/BG2.jpg);
  }
}
@media screen and (min-width: 514px) and (max-width: 768px) {
  .main .content {
    background-image: url(/src/assests/BG1.jpg);
  }
}
