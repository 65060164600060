.contact-body {
  margin: 10vh 0 5vh 0;
}

.contact-boby-top {
  position: relative;
  display: flex;
}

.contact-boby-top .contact-img-div {
  position: relative;
  margin: 15vh auto;
}

.contact-boby-top .contact-img-div .contact-img {
  width: 12em;
}

.contact-boby-top .contact-content * {
  font-family: 'Edu SA Beginner', cursive;
  margin: auto;
  text-align: start;
}

.contact-boby-top .contact-content {
  margin: 15vh auto auto auto;
  display: flex;
  flex-direction: column;
  font-size: 27px;
  font-weight: bold;
}

.contact-content .social_media .social-media-icon {
  margin: auto 5px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 53px;
}

.contact-content .social_media .social-media-icon * {
  border-radius: 50%;
  padding: 7px;
}

.contact-content .social_media .social-media-icon #gmail-icon {
  color: white;
  background-color: #E74335;
}

.contact-content .social_media .social-media-icon #whatsapp-icon {
  color: white;
  background-color: #63CE49;
}

.contact-content .social_media .social-media-icon #insta-icon {
  color: white;
  background-color: #E7495D;
}

.contact-content .social_media .social-media-icon #fb-icon {
  color: white;
  background-color: #4867AA;
}

.contact-content .social_media .social-media-icon #gt-icon {
  color: white;
  background-color: #1B1F23;
}

.contact-content .social_media .social-media-icon #in-icon {
  color: white;
  background-color: #3B77B5;
}

.contact-content .social_media .social-media-icon #tw-icon {
  color: white;
  background-color: #52A2F3;
}

.contact-content .social_media .social-media-icon #gmail-icon:hover {
  background-color: white;
  color: #E74335;
}

.contact-content .social_media .social-media-icon #whatsapp-icon:hover {
  background-color: white;
  color: #63CE49;
}

.contact-content .social_media .social-media-icon #insta-icon:hover {
  background-color: white;
  color: #E7495D;
}

.contact-content .social_media .social-media-icon #fb-icon:hover {
  background-color: white;
  color: #4867AA;
}

.contact-content .social_media .social-media-icon #gt-icon:hover {
  background-color: white;
  color: #1B1F23;
}

.contact-content .social_media .social-media-icon #in-icon:hover {
  background-color: white;
  color: #3B77B5;
}

.contact-content .social_media .social-media-icon #tw-icon:hover {
  background-color: white;
  color: #52A2F3;
}

.contact-content .btns-contact {
  border: none;
  box-sizing: border-box;
  margin: 30px;
  padding: 5px 30px;
  font-weight: 600;
  color: #5539b9;
  border: 1px solid white;
  border-radius: 5px;
  transition: .4s;
}

.contact-content .btns-contact:hover {
  background-color: #847bae;
  border: 1px solid white;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 1180px) {
  .contact-boby-top .contact-img-div .contact-img {
    width: 10em;
  }

  .contact-boby-top .contact-content {
    font-size: 22px;
  }
}

@media screen and (max-width: 960px) {
  .contact-boby-top .contact-img-div .contact-img {
    margin-top: 35px;
    width: 8em;
  }

  .contact-boby-top .contact-content {
    font-size: 21px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 4px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 40px;
  }
}

@media screen and (max-width: 830px) {

  .contact-boby-top .contact-img-div .contact-img {
    margin-top: 30px;
  }

  .contact-boby-top .contact-content {
    font-size: 19px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 3.5px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 35px;
  }

  .contact-content .btns-contact {
    margin: 20px;
    padding: 4px 25px;
  }
}

@media screen and (max-width: 750px) {
  .contact-boby-top .contact-img-div .contact-img {
    width: 7em;
  }

  .contact-boby-top .contact-content {
    font-size: 18px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 3.2px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 32px;
  }

  .contact-content .btns-contact {
    margin: 19px;
    padding: 3.8px 23px;
  }
}

@media screen and (max-width: 655px) {
  .contact-boby-top .contact-img-div .contact-img {
    width: 6.5em;
  }

  .contact-boby-top .contact-content {
    font-size: 16px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 3.2px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 28px;
  }

  .contact-content .btns-contact {
    margin: 17px;
    padding: 3.8px 23px;
  }
}

@media screen and (max-width: 455px) {
  .contact-boby-top .contact-img-div .contact-img {
    margin-top: 2.2em;
    width: 5em;
  }

  .contact-boby-top .contact-content {
    font-size: 13px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 2.5px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 18px;
  }

  .contact-content .btns-contact {
    margin: 10px;
    padding: 3px 13px;
  }
}

@media screen and (max-width: 300px) {
  .contact-boby-top .contact-img-div .contact-img {
    margin-top: 3em;
    width: 3em;
  }

  .contact-boby-top .contact-content {
    font-size: 10px;
  }

  .contact-content .social_media .social-media-icon * {
    padding: 2px;
  }

  #gmail-icon,
  #whatsapp-icon,
  #insta-icon,
  #fb-icon,
  #gt-icon,
  #in-icon,
  #tw-icon {
    font-size: 15px;
  }

  .contact-content .btns-contact {
    margin: 3px;
    padding: 2px 9px;
  }
}