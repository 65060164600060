header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 100px;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
}

.navigation li p,
.home-logo {
  cursor: pointer;
}

header .home-logo {
  color: #ffffff;
  font-weight: 500;
  font-size: 2em;
  text-decoration: none;
}

header .navigation li p {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
}

header .home-logo:hover,
header .navigation li p:hover {
  color: #000;
  /* background-color: #e2c5c567; */
}

header .navigation {
  position: relative;
  display: flex;
}

header .navigation li {
  list-style: none;
  margin-left: 30px;
  padding-top: 20px;
}

header .znav div {
  height: 4px;
  background-color: white;
  margin: 5px 0;
  border-radius: 25px;
  transition: 0.3s;
}

header .znav {
  width: 30px;
  display: block;
  margin: 1em 0 0 1em;
}

header .znav .one-bar {
  width: 30px;
}

header .znav .two-bar {
  width: 20px;
}

header .znav:hover div {
  min-width: 30px;
}

header .znav .three-bar {
  width: 25px;
}

header nav {
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  header .navigation,
  header .home-logo,
  .nav-link {
    display: none;
  }

  header {
    padding: 0;
  }
}

@media screen and (min-width: 951px) {
  header .znav {
    display: none;
  }
}