.payment-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.payment-success .payment {
    border: 1px solid #f2f2f2;
    width: 600px;
    padding-bottom: 20px;
    border-radius: 20px;
    background: #fff;
}

.payment-success .payment_header {
    background: #113;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
}

.payment-success .check {
    margin: 0px auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    text-align: center;
}

.payment-success .check i {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
}

.payment-success .content {
    text-align: center;
}

.payment-success .content h1 {
    font-size: 25px;
    padding-top: 25px;
}

.payment-success .content a:hover {
    text-decoration: none;
    background: #000;
}

@media screen and (max-width:680px) {
    .payment-success .payment {
        width: 500px;
    }
}

@media screen and (max-width:550px) {
    .payment-success .payment {
        width: 400px;
    }
}

@media screen and (max-width:460px) {
    .payment-success .payment {
        width: 300px;
        padding-bottom: 7px;
    }

    .payment-success .payment_header {
        padding: 10px;
    }

    .payment-success .check {
        width: 25px;
        height: 25px;
    }

    .payment-success .check i {
        line-height: 25px;
        font-size: 15px;
    }

    .payment-success .content h1 {
        font-size: 18px;
        padding-top: 15px;
    }

    .payment-success .content p {
        font-size: 15px;
    }

    .payment-success .payment h6 {
        font-size: 12px;
    }
}

@media screen and (max-width:360px) {
    .payment-success .payment {
        width: 230px;
        padding-bottom: 4px;
    }

    .payment-success .payment_header {
        padding: 8px;
    }

    .payment-success .check {
        width: 23px;
        height: 23px;
    }

    .payment-success .check i {
        line-height: 23px;
        font-size: 13px;
    }

    .payment-success .content h1 {
        font-size: 16px;
        padding-top: 13px;
    }

    .payment-success .content p {
        font-size: 13px;
    }

    .payment-success .payment h6 {
        font-size: 10px;
    }

}