input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.feedback {
  margin: 20vh 7vw 15vh 7vw;
  height: 65vh;
}

.feedback-body {
  display: flex;
  justify-content: space-between;
}

.feedback-body textarea:focus,
.feedback-body input:focus {
  outline: none;
}

.feedback-body .feedback-form {
  width: 100%;
  max-width: 320px;
}

.feedback-body input,
.feedback-body textarea,
.feedback-body button {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f5f5f5;
  width: 300px;
  display: block;
  margin-bottom: 15px;
  resize: none;
  border: none;
  font: inherit;
  border-radius: 2%;
  padding: 10px;
}

.feedback-body input,
.feedback-body textarea {
  padding-left: 20px;
}

.feedback-body input {
  height: 50px;
}

.feedback-body ::placeholder {
  color: #112;
  opacity: 0.5;
}

.feedback-body button {
  background-color: #112;
  color: #fff;
}

.feedback-body button:hover {
  background-color: #123;
}

.feedback-body .onsubmit {
  position: relative;
  width: 100%;
}

.feedback-body .onsubmit h4 {
  font-weight: 600;
  position: absolute;
  padding: 2px;
  width: 100%;
  color: rgba(0, 0, 0, 0.589);
}

.feedback-body .onsubmit h4#email-success {
  background-color: rgba(80, 235, 49, 0.548);
  transition: .2s;
}

.feedback-body .onsubmit h4#email-fail {
  background-color: rgba(235, 49, 49, 0.548);
  transition: .2s;
}

.feedback-body .thank-you-div {
  max-width: 400px;
  position: relative;
}

.feedback-body .thank-you-div .upi-img {
  max-width: 230px;
}

.card-payment-div {
  /* border: 2px solid #112; */
  position: relative;
  width: 25vw;
  height: 45vh;
  transition: .5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.amount-form input,
.amount-form .card-next-btn {
  width: 350px;
}

.card-payment-div .card-next-btn {
  margin-bottom: 5px;
}

.card-logo {
  float: right;
  margin-top: 0;
}

.card-payment-form-div {
  width: 20vw;
  position: relative;
}

@media screen and (max-width:1300px) {
  .feedback {
    margin: 20vh 4vw 15vh 4vw;
    height: 65vh;
  }

  .feedback-body .feedback-form {
    width: 100%;
    max-width: 250px;
  }

  .feedback-body input,
  .feedback-body textarea,
  .feedback-body button {
    width: 250px;
  }

  .feedback-body input {
    height: 50px;
  }

  .feedback-body .thank-you-div {
    max-width: 300px;
  }

  .feedback-body .thank-you-div .upi-img {
    max-width: 180px;
  }

  .card-payment-div {
    width: 250px;
    height: 45vh;
    transition: .5s;
  }

  .amount-form input,
  .amount-form .card-next-btn {
    width: 250px;
  }
}

@media screen and (max-width:950px) {
  .feedback {
    margin: 20vh auto;
  }

  .feedback-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .feedback-body .feedback-form {
    max-width: 420px;
  }

  .feedback-body input,
  .feedback-body textarea,
  .feedback-body button {
    width: 400px;
  }

  .feedback-body input {
    height: 60px;
  }

  .feedback-body .thank-you-div {
    position: relative;
    max-width: 400px;
    margin: 50px auto;
  }

  .feedback-body .thank-you-div .upi-img {
    max-width: 230px;
  }

  .card-payment-div {
    position: relative;
    width: 400px;
    transition: .5s;
    margin-bottom: 30px;
    align-items: flex-start;
  }

  .amount-form input,
  .amount-form .card-next-btn {
    width: 400px;
  }
}

@media screen and (max-width:460px) {
  .feedback {
    margin: 10vh auto;
  }

  .feedback-body .feedback-form {
    max-width: 270px;
  }

  .feedback-body input,
  .feedback-body textarea,
  .feedback-body button {
    width: 300px;
  }

  .feedback-body input,
  .feedback-body button {
    height: 40px;
  }

  .feedback-body .thank-you-div {
    max-width: 300px;
    margin: 45px auto;
  }

  .feedback-body .thank-you-div .upi-img {
    width: 180px;
  }

  .card-payment-div {
    width: 300px;
  }

  .amount-form input,
  .amount-form .card-next-btn {
    width: 300px;
  }
}

@media screen and (max-width:350px) {
  .feedback {
    margin: 10vh auto;
  }

  .feedback-body .feedback-form {
    max-width: 270px;
  }

  .feedback-body input,
  .feedback-body textarea,
  .feedback-body button {
    width: 250px;
  }

  .feedback-body input,
  .feedback-body button {
    height: 40px;
  }

  .feedback-body .thank-you-div {
    max-width: 250px;
    margin: 45px auto 10px auto;
  }

  .feedback-body .thank-you-div .upi-img {
    width: 150px;
  }

  .card-payment-div {
    width: 250px;
  }

  .amount-form input,
  .amount-form .card-next-btn {
    width: 250px;
  }
}