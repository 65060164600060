.work-body {
  margin: 17vh 5vw auto 5vw;
}

.work-boby-top {
  display: flex;
  justify-content: space-between;
}

.work-boby-top .work-content {
  margin: auto;
  padding-right: 30px;
  flex: 3;
  font-size: 27px;
  font-weight: bold;
}

.work-boby-top .work-content * {
  font-family: "Edu SA Beginner", cursive;
  margin: auto;
  text-align: start;
}

.work-boby-top .work-content h2 {
  font-size: 34px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.work-boby-top .work-content .role-bold {
  color: white;
}

.job-link {
  text-decoration: none;
  color: rgb(193, 209, 49);
  text-decoration: underline;
}

.job-link:hover {
  color: rgb(68, 64, 64);
}

.work-highlight {
  text-decoration: underline;
}

.work-body .work-content .exp-arrow {
  float: right;
  cursor: pointer;
}

.work-body .work-content .exp-arrow:hover {
  color: white;
}

/* .fadeOut {
  opacity: 0;
  filter: blur(5px);
  transform: translate(-100%);
  transition: all 1s;
}
.fadeIn {
  opacity: 1;
  filter: blur(0);
  transform: translate(0);
}*/

.work-boby-top .work-img-div {
  margin: auto;
  position: relative;
  flex: 2;
}

.work-boby-top .work-img-div .work-img {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .work-boby-top .work-content {
    font-size: 24px;
  }
}

@media screen and (max-width: 1150px) {
  .work-boby-top .work-content {
    flex: 2;
    font-size: 22px;
  }

  .work-boby-top .work-img-div {
    flex: 1;
  }
}

@media screen and (max-width: 950px) {
  .work-boby-top .work-content {
    flex: 1;
    font-size: 20px;
  }

  .work-boby-top .work-content h2 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 880px) {
  .work-boby-top .work-content {
    font-size: 18px;
  }

  .work-boby-top .work-content h2 {
    font-size: 1.2em;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 750px) {
  .work-body {
    margin: 5em 2em;
  }

  .work-boby-top {
    flex-direction: column;
  }

  .work-boby-top .work-content {
    font-size: 1.3em;
    margin-bottom: 70px;
    padding-right: 0px;
  }

  .work-boby-top .work-content h2 {
    font-size: 1.2em;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 500px) {
  .work-boby-top .work-content {
    font-size: 1em;
    margin-bottom: 50px;
  }

  .work-boby-top .work-content h2 {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 300px) {
  .work-boby-top .work-content {
    font-size: 0.6em;
    margin-bottom: 30px;
  }

  .work-boby-top .work-content h2 {
    font-size: 0.8em;
    margin-bottom: 6px;
  }
}
