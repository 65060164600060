.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    min-width: 250px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.card-payment-form-div button:hover {
    filter: contrast(15%);
}

.card-payment-form-div button:disabled {
    opacity: 0.5;
    cursor: default;
}

/* spinner/processing state, errors */
.card-payment-form-div .spinner,
.card-payment-form-div .spinner:before,
.card-payment-form-div .spinner:after {
    border-radius: 50%;
}

.card-payment-form-div .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.card-payment-form-div .spinner:before,
.card-payment-form-div .spinner:after {
    position: absolute;
    content: '';
}

.card-payment-form-div .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.card-payment-form-div .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ================custom=== */


.payment-form {
    position: relative;
}

.card-error {
    position: absolute;
    margin: 5px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(255, 0, 0, 0.596);
}

.back-arrow-payment p {
    margin: 5px -5px;
    text-align: start;
    cursor: pointer;
}

.back-arrow-payment p #back-payment {
    font-size: 34px;
}

.back-arrow-payment p #back-payment:hover {
    filter: invert(10%);
}

.feedback-body button#payment-submit {
    float: right;
    width: 100px;
    padding: none;
    margin-top: 20px;
}

.payment-success-message {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: #ffffff3a;
}

@media screen and (max-width:950px) {
    .payment-form {
        width: 400px;
    }
}

@media screen and (max-width:460px) {
    .payment-form {
        width: 300px;
    }
}

@media screen and (max-width:350px) {
    .payment-form {
        width: 250px;
    }
}